<template>
  <div class="containerPrincipal">
      <div class="containerCentral">
        <div class="menuEtapas">
          <button :class="modalUltimasAtualizacoesVue ? 'buttonEtapasSelecionado' : 'buttonEtapasNaoSelecionado'">Atualizações</button>
          <div class="linhaEntreOsBotoes"></div>
          <button :class="modalPreVisualizarAtividades ? 'buttonEtapasSelecionado' : 'buttonEtapasNaoSelecionado'">Editar</button>
          <div class="linhaEntreOsBotoes"></div>
          <button :class="modalVisualizarEnviar ? 'buttonEtapasSelecionado' : 'buttonEtapasNaoSelecionado'">Visualizar e Enviar</button>
        </div>
        <div class="divisao"></div>
        <div >
          <ModalUltimasAtualizacoesVue @emit-selecionadas="atividadesSelecionadas" v-if="modalUltimasAtualizacoesVue" />
          <ModalPrevisualizarAtividadesVue @emit-atividadesEditadas="atividadesEditadas" v-if="modalPreVisualizarAtividades" :atividades="Selecionadas"/>
          <ModalVisualizarEnviarVue v-if="modalVisualizarEnviar" :editadas="editadas"/>
          <div :class="this.etapa === 1 ? 'containerButtonProximoSpaceEnd' : 'containerButtonProximo'">
          <button v-if="this.etapa !== 1" class="buttonProximo" @click="etapaAnterior">Anterior</button>
          <button class="buttonAnterior" @click="proximaEtapa">{{this.etapa === 3 ? 'ENVIAR' : 'PRÓXIMO'}}</button>
          </div>
        </div>
      </div>
  </div>
  </template>
  
  
  <script>
  import ModalUltimasAtualizacoesVue from '../components/Atualizações/ModalUltimasAtualizacoes.vue';
  import ModalPrevisualizarAtividadesVue from '../components/Atualizações/ModalPrévisualizarAtividades.vue';
  import ModalVisualizarEnviarVue from '../components/Atualizações/ModalVisualizarEnviar.vue';
  import atualizacoesService from '../services/atualizacoes.service';
  import {alertar} from '@/services/notificacao.service.js';

  export default {
    components: {
      ModalUltimasAtualizacoesVue,
      ModalPrevisualizarAtividadesVue,
      ModalVisualizarEnviarVue
    },
    data() {
      return {
      modalUltimasAtualizacoesVue: true,
      modalPreVisualizarAtividades: false,
      modalVisualizarEnviar: false,
      modalVisualizarContrato: false,
      etapa: 1,
      Selecionadas: [],
      editadas: [],
      buttonAnterior: false,
      }
    },

    async mounted() {
      },
    methods: {

      atividadesSelecionadas(atividades) {
        if(!this.buttonAnterior) {
          this.Selecionadas = atividades;
          this.modalPreVisualizarAtividades= true;
        }
      },

      atividadesEditadas(atividades) {
        if(!this.buttonAnterior) {
          this.editadas = atividades
          this.modalVisualizarEnviar = true;
        }
      },

      proximaEtapa() {
      this.buttonAnterior = false
      if(this.etapa === 1) {
      this.modalUltimasAtualizacoesVue = false;
      this.etapa = this.etapa + 1
      return 
     }

     if(this.etapa === 2) {
      this.modalPreVisualizarAtividades = false;
      this.etapa = this.etapa + 1
      return 
     }

     if(this.etapa === 3) {
      this.enviarEmail()
      return  
     }
      },
     etapaAnterior(){
      this.buttonAnterior = true
      if(this.etapa === 2) {
      this.modalPreVisualizarAtividades = false;
      this.modalUltimasAtualizacoesVue = true;
      return this.etapa = this.etapa - 1
     }

     if(this.etapa === 3) {
      this.modalVisualizarEnviar = false;
      this.etapa = this.etapa - 1
      return this.modalPreVisualizarAtividades = true;
     } 
     },

    async enviarEmail() {
      await atualizacoesService.Enviar(this.editadas)
      .then(() => {
        alertar('success', '=)', 'E-mail enviado com sucesso!')
      })
      .catch((error) => {
        console.log(error)
      })
     }
     
    },
  }
  </script>
  
  <style scoped>
  .containerPrincipal {
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  
  .containerCentral {
    display: flex;
    flex-direction: column;;
    background-color: rgb(255, 255, 255);
    width: 80%;
    padding: 20px;
  }
  
  .menuEtapas {
    display: flex;
    width: 70%;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin: auto;
  }
  .buttonEtapasSelecionado {
    background-color: #1377F2;
    padding: 10px;
    border-radius: 5px;
    color: white;
  }
  
  .buttonEtapasNaoSelecionado {
    background-color: #8ab5ff;
    padding: 10px;
    border-radius: 5px;
    color: white;
  }
  
  .linhaEntreOsBotoes {
    background-color: #1377F2;
    flex: 1;
    height: 3px;
  }
  
  .divisao {
    width: 100%;
    height: 1px;
    background-color: #d3d3d3;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .containerButtonProximo {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  
    .containerButtonProximoSpaceEnd {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  
    .buttonProximo {
      background-color: #1377F2;
      color: white;
      padding: 10px;
      width: 100px;
      border-radius: 5px;
    }
  
    .buttonAnterior {
      background-color: #1377F2;
      color: white;
      padding: 10px;
      width: 100px;
      border-radius: 5px;
    }
  </style>