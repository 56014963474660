<template>
  <div style="border: 1px solid #e0e0e0; margin-bottom: 10px;">
    <div>
      <img src="/TemplatePdfHtml/logoes-333w (1).webp" style="width: 100px; margin-top:20px; margin:15px"/>
    </div>
    <div style="text-align: center;color: #1377F2;">
      <h2>Novas atualizações</h2>
    </div> 
     <div style="margin-bottom:20px;margin-top: 20px; margin: 15px;border-bottom: 1px solid #c6c6c6" v-for="(atividades, index) in atualizacoes" :key="index">
      <h3 style="color: #1377F2"><strong>Software:</strong> {{ atividades.software }}</h3>
      <div style="border-top: 1px solid #c6c6c6; margin-top: 10px;margin-bottom: 10px;" v-for="(funcionalidade, index) in atividades.funcionalidades[0]" :key="index">
        <p style="margin-top: 10px;"><strong>Funcionalidade:</strong> {{ funcionalidade.Funcionalidade }}</p>
        <p><strong>Descrição:</strong> {{ funcionalidade['Descrição'] }}</p>
      </div>
    </div>
    </div>
</template>

<script>

export default {
  props:['editadas'],
  data(){
    return{
      atualizacoes: [],
    }
  },
  mounted(){
    let att = []
    this.editadas.forEach((e) => {
      const obj = {
        software: e.projeto
      }
      att = [...att, obj]
    })
    
    var repeated = [];
    
    att.forEach((elemem =>{
      if(!repeated.includes(elemem.software)){
        repeated.push(elemem.software)
      }
    }));

    let nvObj = [];

    repeated.forEach((e) => {
      const obj = {
        software: e
      }
      nvObj = [...nvObj, obj]
    })
    
    let array = []
  
    nvObj.forEach((e, i) => {
      array = []
      this.editadas.forEach((e2) => {
        if(e.software === e2.projeto) {
          const obj = {
            Funcionalidade: e2.atividade,
            Descrição: e2.descricao
          }
          array = [...array, obj]
        }
        nvObj[i].funcionalidades = [array]
      })
    })
    console.log(nvObj)
    this.atualizacoes = nvObj
  }
}
</script>

<style scoped lang="scss">
</style>