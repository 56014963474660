<template>
  <div>
    <div class="containerFiltroData">
      <div class="ContainerinputDataEstimativa">
        inicial:
        <input class="textFieldDateFiltroRelatorio" type="date" v-model="dataConclusao.data_inicial"/>
        final:
        <input class="textFieldDateFiltroRelatorio" type="date" v-model="dataConclusao.data_final"/>
        <div style="display: flex; margin-top:20px; margin-bottom:20px">
          <v-select style="margin-right: 20px;" class="select-input" color v-model="setorSelecionado" label="Setor" :items="Setor"
            outlined return-object hide-details dense>
          </v-select>
          <v-select v-model="sistema" label="Sistema" item-text="nome" item-value="nome" :items="listadesistema" outlined return-object hide-details dense >
         </v-select>
        </div>
     
        <button class="buttonProximo" @click="buscarAtividadesData">BUSCAR</button>
      </div>
    </div>
    <v-data-table style="margin-top: 20px; margin-bottom: 20px" :items-per-page="10" :headers="headers" :items="atividades " class="elevation-1" >
      <template v-slot:[`item.action`]="{ item }">
      <input type="checkbox" @change="(event) => selecionarAtividade(event, item)"/>
       </template>
    </v-data-table>
  </div>
</template>

<script>
  import atulizacoes from  '../../services/atualizacoes.service';
  import dayjs from 'dayjs';
  import SistemaServico from '@/services/sistema.service.js'
  export default {
    data() {
      return{
        atividades: [],
        selecionadas: [],
        listadesistema: [],
        sistema: 'Todos',
        setorSelecionado: 'Todos',
        Setor: ['Todos', 'Desenvolvimento', 'Suporte'],
        dataConclusao: {
          data_inicial: null,
          data_final: null,
        },
        headers:[
                { text: "Projeto", value: "projeto", width:'25%'},
                { text: "Atividade", value: "atividade", width:'15%'},
                { text: "Descrição", value: "descricao", width:'25%'},
                { text: "Dev", value: "usuario", width:'15%'},
                { text: "Ações", value: "action", width:'15%'},
            ],
      }
    },
    beforeDestroy() {
    this.$emit('emit-selecionadas', this.selecionadas)
    },
    async mounted() {
      await this.buscarAtividadesConcluidas()
      await this.requisitarSistemas()
      this.dataConclusao.data_inicial = dayjs().format('YYYY-MM-DD');
      this.dataConclusao.data_final = dayjs().format('YYYY-MM-DD')
    },
    methods: {
      async buscarAtividadesConcluidas() {
        const data = {
          dataConclusaoInicial: dayjs().format('YYYY-MM-DD'),
          dataConclusaoFinal: dayjs().format('YYYY-MM-DD'),
        }
        atulizacoes.buscar(data)
        .then((resp) => {
          this.atividades = resp.data;
        })
        .catch((error) => {
          console.log(error);
        })
      },

      async requisitarSistemas(){
      let sistemas = await SistemaServico.buscar({semimagem:1}).then(res => res.data)
      this.listadesistema = [{id:0,nome:'Todos'}]
      this.listadesistema = this.listadesistema.concat(sistemas)
      },

      async buscarAtividadesData() {
        const data = {
          dataConclusaoInicial: this.dataConclusao.data_inicial,
          dataConclusaoFinal: this.dataConclusao.data_final,
          tipo_usuario: this.setorSelecionado,
          projeto: this.sistema.nome
        }
        atulizacoes.buscar(data)
        .then((resp) => {
          this.atividades = resp.data;
        })
        .catch((error) => {
          console.log(error);
        })
      },

      selecionarAtividade(event, atividade){
        const checked = event.target.checked
        if(checked) {
          this.selecionadas = [...this.selecionadas, atividade]
        }
        if(!checked) {
          const remove = this.selecionadas.filter((e) => e.descricao !== atividade.descricao);
          this.selecionadas = remove;
          console.log(remove)
        }
      }
    },
  }
</script>

<style scoped>
.textFieldDateFiltroRelatorio {
border: 1px solid rgb(196, 196, 196);
margin-right: 10px;
margin-left: 5px;
border-radius: 5px;
padding: 5px;
}

.containerFiltroData {
  display: flex;
}

.buttonProximo {
      background-color: #1377F2;
      color: white;
      padding: 8px;
      width: 100px;
      border-radius: 5px;
    }
</style>