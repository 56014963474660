import api from './api'

export default {
  buscar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/atualizacoes/buscarAtualizacoes', body, config)
  },

  Enviar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/atualizacoes/enviarAtualizacoes', body, config)
  },
}