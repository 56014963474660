<template>
  <div>
    <v-data-table style="margin-top: 20px; margin-bottom: 20px" :items-per-page="10" :headers="headers" :items="atividadesData" class="elevation-1" >
      <template v-slot:[`item.action`]="{ item }">
        <v-btn small class="ml-2" style="text-align:center;"
          @click="editarAtividade(item)"
        >
        <v-icon color="green" title="Editar">
         mdi-pencil
        </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="exibirModalEditarAtividade" width="80vw" max-width="600px">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;padding: 10px;">
            <input class="inputText" v-model="Software" type="text" placeholder="Software"/>
            <input class="inputText" v-model="Funcionalidade" type="text" placeholder="Funcionalidade"/>
            <input class="inputText" v-model="Descrição" type="text" placeholder="Descrição"/>
            <div>
              <button class="buttonConcluir" @click="concluirEdicao" type="button">Concluir</button>
              <button class="buttonConcluir" @click="() => this.exibirModalEditarAtividade = false" type="button">Cancelar</button>
            </div>
          </div>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props:['atividades'],
    data(){
      return{
        headers:[
                { text: "Software", value: "projeto", width:'25%'},
                { text: "Funcionalidade", value: "atividade", width:'15%'},
                { text: "Descrição", value: "descricao", width:'25%'},
                { text: "Ações", value: "action", width:'15%'},
            ],
      exibirModalEditarAtividade: false,
      Software: null,
      Funcionalidade: null,
      atividadesData: [],
      Descrição: null,
      indexAtividade: null
      }
    },
    beforeDestroy() {
    this.$emit('emit-atividadesEditadas', this.atividadesData)
    },
    mounted(){
      this.atividadesData = this.atividades;
      console.log('pré visualizar', this.atividades)
    },
    methods: {
      editarAtividade(item) {
        const filterIndex = this.atividadesData.findIndex((e) =>  e.descricao === item.descricao);
        this.Funcionalidade = item.atividade;
        this.Descrição = item.descricao;
        this.Software = item.projeto;
        this.exibirModalEditarAtividade = true;
        this.indexAtividade = filterIndex;
        console.log(filterIndex);
      },
      concluirEdicao() {
        const obj = {
          projeto: this.Software,
          atividade: this.Funcionalidade,
          descricao: this.Descrição
        }
        const add = [...this.atividadesData];

        add[Number(this.indexAtividade)] = obj;
        this.atividadesData = [...add];
        console.log(this.atividadesData)
        this.exibirModalEditarAtividade = false;
        this.indexAtividade = null
      },
    }
  }

</script>

<style scoped>
.inputText {
  background-color: white;
  width: 100%;
  border: 1px solid rgb(173, 173, 173);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}

.buttonConcluir {
      background-color: #1377F2;
      color: white;
      padding: 10px;
      width: 100px;
      border-radius: 5px;
      margin: 5px;
    }
</style>